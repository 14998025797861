import { Box, Stack, Typography } from "@mui/material"
import React from "react"

const NftCard = ({ nft }) => {
  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{ cursor: "pointer", position: "relative" }}>
      <Box
        sx={{
          width: "100%",
          height: "10px",
          backgroundColor: nft?.isOnSell ? "green" : "red"
        }}
      />
      <Typography
        variant="h5"
        sx={{
          position: "absolute",
          top: 10,
          left: 10,
          textShadow: "-1px -1px 2px black, 1px 1px 2px black"
        }}>
        {nft?.token_id}
      </Typography>

      <Box sx={{ m: 1 }}>
        <img
          src={nft?.metadata?.image}
          width={"100%"}
          height="auto"
          alt="img"
        />
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="body2" sx={{ display: "block", fontWeight: 600 }}>
          {nft?.metadata?.name}
        </Typography>

        {/* <Typography variant="caption">{nft?.metadata?.description}</Typography> */}

        {/* {nft?.metadata?.price && (
          <Typography variant="body1" fontWeight={600}>
            {nft?.metadata?.price}
          </Typography>
        )} */}
      </Box>
    </Stack>
  )
}

export default NftCard
