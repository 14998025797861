import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme
} from "@mui/material"
import React from "react"
import CloseIcon from "@mui/icons-material/Close"

function MuiDialogTitle(props) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff"
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}
const SimpleDialog = ({ isOpen, title, handleClose, children, action }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth>
      <MuiDialogTitle
        DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}>
        {title}
      </MuiDialogTitle>
      <DialogContent sx={{ pt: "16px !important" }}>{children}</DialogContent>
      {action && <DialogActions>{action}</DialogActions>}
    </Dialog>
  )
}

export default SimpleDialog
