import { FormControlLabel, Stack, Switch } from "@mui/material"
import React from "react"

const EditNft = ({ formik }) => {
  return (
    <>
      {false ? (
        <Stack
          height={"100px"}
          direction="row"
          justifyItems={"center"}
          alignItems={"center"}>
          Loading
        </Stack>
      ) : (
        <Stack spacing={2}>
          <Stack direction="row" alignItems={"center"} spacing={1}>
            {/* <CategoryField
              name="price"
              value={formik.values?.price}
              onChange={formik.handleChange}
              label="price"
              error={formik.touched?.price && Boolean(formik.errors?.price)}
              helperText={formik.touched.price ? formik.errors.price : ""}
              fullWidth
            /> */}
            <Stack>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.isOnSell}
                    onChange={formik.handleChange}
                    name="isOnSell"
                  />
                }
                label="Sell"
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  )
}

export default EditNft
