import React from "react"
import Dashboard from "./Dashboard"
import { Routes, Route } from "react-router-dom"
import NoteFound from "../NotFound"
import ManageAdmins from "./ManageAdmins"
import Category from "./Category/Category"
const Admin = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/manageadmins" element={<ManageAdmins />} />
      {/* <Route path="/category" element={<Category />} /> */}
      <Route path="*" element={<NoteFound />} />
    </Routes>
  )
}

export default Admin
